import React from "react";
import classes from "./TeammemberModal.module.css";

function TeamMemberModal({ member, onClose }) {
  return (
    <div className={classes.modalOverlay} onClick={onClose}>
      <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
        <button className={classes.closeButton} onClick={onClose}>&times;</button>
        <img src={member.image} alt={member.name} className={classes.modalImage} />
        <h2>{member.name}</h2>
        {member.role && <p className={classes.modalRole}>{member.role}</p> }
        {/* {member.education &&  <p><strong>Education:</strong> {member.education}</p> } */}
        {/* {member.joiningDate && <p><strong>Joining Date:</strong> {member.joiningDate}</p> } */}
        {member.description && <p className={classes.modalDescription}>{member.description}</p>}
      </div>
    </div>
  );
}

export default TeamMemberModal;

import React, { useState } from "react";
import styles from "./Projects.module.css";
import p1 from "./assests/p1.jpg"; 
import p2 from "./assests/Dasho.png";
import p3 from "./assests/Invoice.jpeg";
import p4 from "./assests/HelloT.png";
import p5 from "./assests/Nijje .png";
import p6 from "./assests/AnyQuant.jpeg"
import p7 from "./assests/D-space.png"
import p8 from "./assests/Ea-Tech.jpg"
import p9 from "./assests/IHS.png"
import p10 from "./assests/Abriella.png";
import p11 from "./assests/block-chain.jpg"


const projectsData = [
  {
    id: 2,
    title: "DashoApp Mobile App",
    content: "DashoApp is an advanced EdTech and ERP platform designed to transform the way educational institutes operate. Built with state-of-the-art technology, DashoApp integrates powerful tools for institute management, learning management, community engagement, and essential utilities—all in one seamless ecosystem. With its sophisticated architecture and highly scalable infrastructure, DashoApp simplifies complex administrative tasks, streamlines communication, and enhances the learning experience with modern AI-driven solutions.",
    status: "completed",
    image: p2,
  features:  [
      "AI-Powered Insights – Automated data analytics for student performance, attendance tracking, and assessment monitoring.",
      "Real-Time Communication – Integrated chat, bulk SMS, email, and video conferencing tools for seamless collaboration.",
      "Automated Fee & Payment System – Secure and automated fee collection with real-time tracking and reports.",
      "Dynamic LMS (Learning Management System) – A feature-rich course management system for interactive learning and progress tracking.",
      "Biometric & Digital Attendance – Advanced biometric integration for accurate attendance tracking.",
      "Smart Notice & Notification System – Automated notices and reminders for classes, exams, and assignments.",
      "Cloud-Based Architecture – Ensuring 99.9% uptime and seamless multi-device accessibility.",
      "Secure & Scalable Infrastructure – Built with React.js, Node.js, MongoDB, and AWS, ensuring high-speed performance and security."
    ],
    modules: [
      {
        "name": "ERP (Enterprise Resource Planning)",
        "description": "Designed for seamless institute management, this module simplifies administrative tasks and automates workflows.",
        "features": [
          "Institute Profile Management – Centralized control over institute details.",
          "People Management – Manage students, teachers, and staff with real-time data.",
          "Batch & Timetable Management – Automate scheduling and batch allocation.",
          "Fee & Payments – Digital payments with detailed reports.",
          "Leave & Attendance – Integrated with biometric systems for precise tracking."
        ]
      },
      {
        "name": "LMS (Learning Management System)",
        "description": "A feature-rich system to elevate online and offline learning experiences.",
        "features": [
          "Course Creation & Syllabus Management – Customize and manage courses effortlessly.",
          "Assignment & Exam Modules – Smart assessment tracking and auto-grading tools.",
          "Class Discussion & Collaboration – Interactive forums and chat support for students and faculty."
        ]
      },
      {
        "name": "Community Engagement",
        "description": "Enhancing communication and collaboration across the institute.",
        "features": [
          "Real-Time Chats & Messaging – Integrated chat for seamless discussions.",
          "Connect & Social Engagement – Build a strong educational community.",
          "Post & Announcements – Instant updates on important events and notices."
        ]
      },
      {
        "name": "Utility Tools & Smart Integration",
        "description": "A powerful suite of essential tools to streamline operations and enhance communication.",
        "features": [
          "AI-Powered Video Conferencing – Jitsi integration for smooth virtual classrooms.",
          "Email & Bulk SMS Automation – Instant notifications to students, parents, and staff.",
          "Smart Contact Management – Organize and store essential contacts securely."
        ]
      }
    ]
  },
  
  {
    id: 2,
    title: "DashoApp Mobile App",
    content: "Dasho Mobile App is the ultimate education management solution, bringing the power of DashoApp directly to your smartphone. Designed with an intuitive interface and packed with advanced features, the app ensures that students, teachers, and administrators can stay connected and manage educational activities effortlessly from anywhere. Built using React Native, Node.js, and AWS cloud infrastructure, the Dasho mobile app delivers a fast, secure, and scalable experience. Whether you're tracking attendance, managing courses, scheduling classes, or making payments, Dasho Mobile makes it easy with just a few taps.",
    status: "completed",
    image: p1,
    features: [
      "Smart Learning Management (LMS) – Course & Syllabus Management, Assignment & Exam Tracking, Class Discussions",
      "Institute Management on the Go (ERP) – Timetable & Schedule Management, Student & Teacher Profiles, Attendance & Leave Requests, Online Fee Payment & Invoice Tracking",
      "Real-Time Communication & Collaboration – Push Notifications, Smart Alerts, Chat & Messaging System, Notice Board & Announcements",
      "Advanced Utility & AI-Powered Tools – Live Classes & Video Conferencing, File Sharing & Document Uploads, AI-Driven Analytics & Reports"
    ],
    modules: [
      {
        "name": "Smart Learning Management (LMS)",
        "description": "Enhancing education through structured courses and real-time assessments.",
        "features": [
          "Course & Syllabus Management",
          "Assignment & Exam Tracking",
          "Class Discussions"
        ]
      },
      {
        "name": "Institute Management on the Go (ERP)",
        "description": "Efficient institute management with automation and real-time tracking.",
        "features": [
          "Timetable & Schedule Management",
          "Student & Teacher Profiles",
          "Attendance & Leave Requests",
          "Online Fee Payment & Invoice Tracking"
        ]
      },
      {
        "name": "Real-Time Communication & Collaboration",
        "description": "Seamless communication and alerts for improved engagement.",
        "features": [
          "Push Notifications & Smart Alerts",
          "Chat & Messaging System",
          "Notice Board & Announcements"
        ]
      },
      {
        "name": "Advanced Utility & AI-Powered Tools",
        "description": "AI-driven insights and virtual learning enhancements.",
        "features": [
          "Live Classes & Video Conferencing",
          "File Sharing & Document Uploads",
          "AI-Driven Analytics & Reports"
        ]
      }
    ]
  },


  {
    id: 3,
    title: "No-Code Trading Platform – Simplifying Strategy Optimization",
    content: "We are developing a no-code trading platform designed to empower traders by enabling them to backtest and optimize complex trading strategies without any programming knowledge. Our platform provides an intuitive, user-friendly interface, making advanced trading accessible to everyone.",
    status: "ongoing",
    image: p6,
    features: [
      "Automated Strategy Creator – Define and refine trading strategies effortlessly using a visual, drag-and-drop interface.",
      "Advanced Backtesting Engine – Test strategies against historical data to evaluate performance and risk.",
      "Optimization Algorithms – Enhance trading strategies with AI-driven parameter tuning for maximum efficiency.",
      "Real-Time Analytics – Monitor market conditions, track performance, and make data-driven decisions instantly.",
      "Core Trading Functionalities",
      "Market Order Execution: Instant execution of buy/sell orders at market price.",
      "Limit & Stop Orders: Set predefined price levels for trade execution.",
      "Algo Trading Strategies: Supports multiple algorithms (e.g., Mean Reversion, Momentum Trading, Arbitrage).",
      "Backtesting Engine: Allows users to test strategies with historical data.",
      "Paper Trading Mode: Simulated trading environment for strategy testing without real money.",
      "Global Timing & Market Synchronization",
      "Real-time Global Market Clocks (e.g., NYSE, LSE, NSE, Tokyo Exchange).",
      "Pre-market & After-market Trading Indicators.",
      "Economic Calendar Integration (Earnings reports, Fed announcements, etc.).",
      "Market Data & Analytics",
      "Live Market Feeds (Stocks, Forex, Crypto, Commodities).",
      "Candlestick & Line Charts with Technical Indicators (RSI, MACD, Bollinger Bands).",
      "Price Alerts & Notifications for significant market movements."
    ]
  },

  {
    id: 4,
    title: "Invoice Builder Project",
    content: "An Invoice Builder system that allows admins to design invoice templates and users to generate, download, and print invoices efficiently.",
    status: "completed",
    image: p3,
    features: [
      "🔹 Admin Features",
      "Template Design & Management: Create, edit, and manage multiple invoice templates.",
      "Predefined Fields: Include invoice number, date, client details, itemized list, and tax calculations.",
      "Branding Customization: Add company logos, adjust colors, and set default fonts.",
      
      "🔹 User Features",
      "Invoice Generation: Input required details (client name, items, taxes) and generate invoices.",
      "Customization Options: Modify colors, fonts, and layouts within pre-set limits.",
      "Save & Edit: Save invoices for future reference and make edits before finalization.",
      
      "🔹 Download & Print Functionalities",
      "Export as PDF: Users can download invoices in a printable format.",
      "Direct Printing: Quick print option for on-the-spot invoicing.",
      "Email Integration: Send invoices directly to clients via email.",
      
      "🔹 Additional Features",
      "Multi-Currency Support: Choose different currencies based on client location.",
      "Tax & Discount Handling: Auto-calculate taxes and apply discounts.",
      "Client Database: Store client details for quick invoice creation."
    ],
  },
  
  {
    id: 5,
    title: "Nijje – Self-Learning App",
    content: "Nijje is a self-learning app designed to help users learn various subjects through structured lessons, quizzes, and progress tracking.",
    status: "completed",
    image: p5,
    features: [
      " Subjects & Topics",
      "Subject Selection: Users can choose from a variety of subjects.",
      "Topic Breakdown: Each subject consists of multiple topics.",
      "Achievements: Completing a subject earns the user a subject medal.",
  
      " Learning & Quizzes",
      "Lesson-Based Learning: Each topic includes multiple lessons, each with a question.",
      "Interactive Quizzes: Users answer questions while learning, and answers are revealed during the quiz.",
      "Topic-Wise Quizzes: A dedicated quiz for each topic based on completed lessons.",
      "Final Subject Test: Unlocks after completing all topics in a subject.",
  
      "Progress Tracking",
      "Resume Functionality: Users can pick up lessons from where they left off.",
      "Quiz Progress Saving: Users retain quiz progress even if they exit.",
      "Points & Badges: Quizzes award points based on performance, and users earn badges for milestones achieved."
    ],
  },
  {
    id: 6,
    title: "HelloToppers",
    content: "HelloToppers is a platform that allows users to book sessions with top achievers, such as IIT toppers, UPSC toppers, and other subject experts. Users can explore categories, find relevant instructors, and schedule one-on-one sessions.",
    status: "completed",
    image: p4,
    features: [
      "Home & Category Selection",
      "Browse Categories: Users can explore various categories like IIT, UPSC, NEET, CA, MBA, etc.",
      "Search Page Navigation: Clicking on a category redirects users to a search page with relevant toppers.",
  
      "Instructor Profiles & Booking",
      "Toppers List: Users can see a list of experts with their profiles.",
      "Profile Details: Clicking on a topper’s profile shows available slots (date & time) and pricing per hour.",
      "Slot Booking: Users can book a preferred slot and receive a confirmation email & SMS.",
  
      "Meeting & Notifications",
      "Meeting Alerts: After booking, users receive a confirmation notification via email & SMS.",
      "Reminder Notification: Sent 1 hour before the session.",
      "Live Session Access: Users log in with their mobile number on HelloToppers, see the meeting status, and join the session from the website."
    ],
  },

  {
    id: 7,
    title: "Dspace: Document Management System for District Courts",
    content: "At Diracai Pvt Ltd, we are developing a comprehensive Judicial Document Management System that revolutionizes how court records are stored, accessed, and managed. Our solution implements a hierarchical file storage structure for judicial records, with robust search capabilities and secure document preservation. This end-to-end solution aims to facilitate the transition to a fully paperless court system while maintaining the highest standards of security, accessibility, and judicial workflow efficiency.",
    status: "ongoing",
    image: p7,
    features: [
      "Hierarchical File Storage Structure for organizing judicial records by civil and criminal categories",
      "Advanced Search Capabilities including:",
      "Multi-language search support (English, Hindi, and regional languages)",
      "Fuzzy search for approximate matches",
      "Full-text search within document content",
      "Boolean and wildcard search mechanisms",
      "Elastic search for enhanced performance",
      "Secure Document Preservation with 256-bit AES encryption and automatic PDF/A conversion",
      "Digital Signature Integration for certified copies with physical DSC Token security",
      "Comprehensive User Management with role-based access controls and permissions",
      "Audit Logging and Activity Tracking for monitoring all system interactions",
      "API Integration for seamless data exchange with external systems",
      "Document Annotation and PDF Masking for sensitive information",
      "Wacom Integration for digital signatures and handwritten annotations",
      "Workflow Automation to enhance judicial efficiency",
      "Fully Paperless Court Module for end-to-end case management"
    ]
  },

  {
    id: 8,
    title: "Improving performance of llama3 LLM model for specific Industries",
    // content: "Will be updated soon..",
    status: "ongoing",
    // image: p4,
    features: [
      <a href="https://youtu.be/AGZUDQdrGPY" target="_blank" rel="noopener noreferrer">Watch on YouTube</a>
    ]
  },

  {
    id: 9,
    title: "Developing Cryptocurrency (using Blockchain) for Assertive US",
    content: "We worked on CRYPTOCOIN development, designing and implementing a secure and scalable blockchain-based cryptocurrency for Assertive US. Our work focused on decentralized transactions, smart contracts, and ensuring the highest level of security and efficiency in the system.",
    status: "ongoing",
    image: p11,
    features: [
      "Custom Cryptocurrency Development – Creating a unique blockchain-based digital asset.",
      "Smart Contract Integration – Implementing secure and automated contracts for transactions.",
      "Decentralized Ledger System – Ensuring transparency and security using blockchain technology.",
      "Scalability & Security – Optimizing the system for high transaction throughput and robust security.",
      "Wallet Development – Providing secure storage and transfer functionalities for users."
    ]
  },
  {
    id: 10,
    title: "Server Installation and Development of Monitoring Dashboard for EAtech",
    content: "We have successfully implemented Odoo CRM to streamline and optimize our customer relationship management processes. This powerful, fully integrated solution allows us to efficiently manage leads, track opportunities, automate sales workflows, and enhance customer interactions.",
    status: "ongoing",
      image: p8,
    features: [
      "Lead & Opportunity Management – Automated lead tracking, smart lead scoring, and seamless conversion to opportunities.",
      "Pipeline Customization – Configurable sales pipelines with drag-and-drop functionality for better workflow management.",
      "Task & Activity Scheduling – Automated follow-ups, reminders, and task assignments to ensure timely responses.",
      "Real-Time Reporting & Analytics – Comprehensive dashboards and data-driven insights to improve decision-making.",
      "Integration with Other Modules – Seamless connection with accounting, inventory, and other Odoo apps for a unified system.",
      "User Role Management – Customized access controls to ensure data security and workflow efficiency."
    ]
  },
  {
    id: 11,
    title: "IT Infra Management and Digital Marketing for Institute of Health Science, Bhubaneswar",
    content: "We have developed a new SEO-optimized website for IHS, ensuring improved visibility and higher search engine rankings. Alongside website optimization, we are actively managing Google Marketing and Digital Marketing campaigns to maximize brand reach and engagement.",
    status: "ongoing",
    image: p9,
    features: [
      "Google Ads & Campaign Management – Running strategic ad campaigns to generate leads and boost conversions.",
      "SEO Optimization – Implementing advanced SEO techniques to enhance organic traffic and search visibility.",
      "Video Production & Editing – Shooting, editing, and publishing engaging video content for digital promotion.",
      "Social Media Marketing – Managing Instagram marketing, creating viral content, and growing audience engagement.",
      "Offline Marketing Strategies – Implementing on-ground promotional activities to complement digital efforts.",
      "Targeted Advertising – Optimized campaigns for maximum ROI.",
      "High-Quality Content – Engaging videos and creatives for brand storytelling.",
      "Multi-Platform Presence – Expanding reach through Google, social media, and offline channels."
    ]
  },

  {
    id: 11,
    title: "E-commerce Website for Abriella Paintings",
    content: "We have developed a fully functional e-commerce website for Abriella Paintings, providing a seamless shopping experience for art enthusiasts across the country. The platform allows users to explore and purchase a wide range of paintings and art products with ease.",
    status: "ongoing",
    image: p10,
    features: [
      "Browse & Purchase Artwork – A user-friendly platform where customers can explore, select, and buy paintings effortlessly.",
      "Nationwide Order Placement – Customers can place orders from anywhere in the country with reliable delivery services.",
      "Secure Payment Gateway – Integrated with multiple payment options for hassle-free transactions.",
      "Worldwide Currency Converter – Allows international customers to view prices in their local currency.",
      "Automated Receipt Generation – Generates invoices for every sale and purchase, ensuring a transparent transaction process."
    ]
  }
];


 

const Projects = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className={styles.projectsContainer}>
      <h2 className={styles.sectionTitle}>Our Projects</h2>
      {projectsData.map((project, index) => (
        <div key={project.id} className={styles.accordionItem}>
          <div className={styles.accordionHeader} onClick={() => toggleAccordion(index)}>
            <span>{project.title}</span>
            <span className={`${styles.accordionArrow} ${activeIndex === index ? styles.open : ""}`}>▼</span>
          </div>
          <div className={`${styles.accordionContent} ${activeIndex === index ? styles.active : ""}`}>
            <div className={styles.projectImageCard}>
              <img src={project.image} alt={project.title} className={styles.projectImage} />
            </div>
            <span className={`${styles.projectStatus} ${styles[`status-${project.status}`]}`}>
              {project.status.charAt(0).toUpperCase() + project.status.slice(1)}
            </span>
            <p className={styles.projectContent}>{project.content}</p>
            <ul className={styles.projectFeatures}>
              {project.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            {project.modules && (
              <div className={styles.projectModules}>
                <h3>Modules:</h3>
                {project.modules.map((module, i) => (
                  <div key={i} className={styles.moduleItem}>
                    <h4>{module.name}</h4>
                    <p>{module.description}</p>
                    <ul className={styles.projectFeatures}>
                      {module.features.map((feature, j) => (
                        <li key={j}>{feature}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Projects;

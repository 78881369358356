import { useParams } from "react-router-dom";
import classes from "./ServicesDetails.module.css";
import Services from "./servicesData";

const ServicesDetails = () => {
  const { id } = useParams(); 
  const data = Services.find(service => service.id === parseInt(id)); 

  if (!data) {
    return <h2 style={{ textAlign: "center", marginTop: "50px", color: "red" }}>Service Not Found</h2>;
  }

  return (
    <div className={classes.parentNews}>
      <div className={classes.imgAndHeading}>
        <img className={classes.mainImg} src={data.image} alt="Service" />
      </div>

      <div className={classes.contentDetails}>
        <h2 className={classes.mainTitle}>{data.title}</h2>
        <p className={classes.newsDetails}>{data.description}</p>

        {data.features && (
          <ul className={classes.featureList}>
            {data.features.map((feature, index) => (
              <li key={index} className={classes.featureItem}>
                {feature}
              </li>
            ))}
          </ul>
        )}
        <p className={classes.newsDetails}>{data.long_description}</p>
      </div>
    </div>
  );
};

export default ServicesDetails;


import s1 from "./assests/full-stack.png";
import s2 from "./assests/ai-ml.png";
import s3 from "./assests/mobile-app.png";
import s4 from "./assests/fintech.png";
import s5 from "./assests/blockchain.png";
import s6 from "./assests/crm-erp.png";
import s7 from "./assests/ams.png";
import s8 from "./assests/ed-tech.png";
import s9 from "./assests/repository.png";
import s10 from "./assests/odoo-erp.png";
import s11 from "./assests/software-training.png";
import s12 from "./assests/sap-graphic-elearning.png"


const Services = [
    {
      id: 1,
      title: "Fullstack Development",
      description:
        "From frontend design to backend infrastructure, we provide a complete fullstack development solution. Our team ensures high-performance, scalable, and secure web applications for businesses of all sizes.",
      long_description:
        "Our Fullstack Development services cover every aspect of web application development, from front-end design to robust backend systems. We leverage the latest technologies such as React, Angular, Vue.js for dynamic and responsive user interfaces, ensuring a seamless experience across devices. On the backend, we utilize frameworks like Node.js, Django, and Spring Boot to build scalable and secure systems. Our expertise extends to API development, enabling efficient communication between different software applications. With a strong focus on database optimization, we ensure your applications run smoothly with high performance. Additionally, we integrate third-party services and cloud solutions to enhance functionality. Our team follows best coding practices, security standards, and continuous deployment strategies to keep applications updated and secure. Whether it's a startup or an enterprise, our solutions are tailored to meet specific business needs.",
      image: s1,
      features: [
        "React, Angular, and Vue.js development",
        "Node.js, Django, and Spring Boot backend solutions",
        "API development and third-party integrations",
        "Database optimization and scalability",
      ],
    },
    {
      id: 2,
      title: "ML / AI Services",
      description:
        "We specialize in developing AI-driven solutions, including machine learning models, large language model (LLM) training, and predictive analytics. Our expertise ensures data-driven decision-making for businesses across industries.",
      long_description:
        "Our Machine Learning and Artificial Intelligence services empower businesses to harness the power of data-driven insights. We develop custom AI models tailored to specific industry needs, ensuring automated decision-making, enhanced productivity, and improved efficiency. With expertise in predictive analytics, our solutions help businesses forecast trends, detect anomalies, and optimize operations. Our work in Natural Language Processing (NLP) includes chatbots, sentiment analysis, and voice recognition systems to improve user interactions. From training large language models (LLMs) to building AI-driven automation tools, we ensure businesses stay ahead of technological advancements. We also integrate AI models into existing systems, enabling seamless workflow improvements. Our team prioritizes ethical AI development, ensuring fairness, transparency, and security in all implementations.",
      image: s2,
      features: [
        "Custom AI model development",
        "Predictive analytics and forecasting",
        "Natural language processing (NLP)",
      ],
    },
    {
      id: 3,
      title: "Mobile App Development",
      description:
        "We build feature-rich, high-performing mobile applications for iOS and Android using cutting-edge technologies such as native app development, Kotlin multi-platform, and Jetpack Compose.",
      long_description:
        "Our Mobile App Development services focus on delivering seamless and user-friendly experiences across both iOS and Android platforms. We specialize in native development using Swift for iOS and Kotlin for Android, ensuring optimized performance and smooth navigation. Additionally, we offer cross-platform solutions with Flutter and React Native, reducing development time while maintaining high quality. Our team takes a user-centric approach, focusing on intuitive UI/UX design for an engaging experience. We provide full-cycle development, from ideation and prototyping to deployment and maintenance. Our expertise includes integrating cloud-based services, real-time databases, and third-party APIs. Whether it's an enterprise app, e-commerce solution, or a social media platform, we tailor our development to meet specific business objectives. Security, scalability, and performance optimization remain our top priorities, ensuring apps run smoothly under high user loads.",
      image: s3,
      features: [
        "Native and cross-platform app development",
        "UI/UX design for seamless user experience",
        "Integration with cloud services and APIs",
        "App store deployment and maintenance",
      ],
    },
    {
      id: 4,
      title: "Finance Solutions",
      description:
        "Optimize trading and financial operations with our AI-powered algorithms, backtesting tools, and data-driven financial analytics.",
      long_description:
        "Our Finance Solutions services help businesses navigate the complexities of financial markets with AI-powered tools. We specialize in algorithmic trading system development, where we implement machine learning algorithms for predictive analysis and automated trading strategies. Our backtesting solutions allow traders and financial institutions to validate their trading strategies using historical data before live execution. We also provide robust risk analysis tools that help businesses assess and mitigate potential financial risks. Our financial data modeling services enable institutions to make informed decisions based on real-time market trends. Additionally, we develop visualization dashboards for financial reporting, helping clients track investment performance, revenue growth, and financial forecasts efficiently. Security is paramount in our solutions, ensuring end-to-end encryption and compliance with industry regulations.",
      image: s4,
      features: [
        "Algorithmic trading system development",
        "Backtesting and risk analysis",
        "Financial data modeling and visualization",
      ],
    },
    {
      id: 5,
      title: "Blockchain Solutions",
      description:
        "We deliver secure and scalable blockchain applications tailored to business needs, ensuring transparency and efficiency in transactions.",
      long_description:
        "Our Blockchain Solutions cater to businesses looking to integrate decentralized technologies for enhanced security and transparency. We specialize in smart contract development, enabling automated and secure transactions on blockchain networks like Ethereum and Binance Smart Chain. Our team builds decentralized applications (DApps) that facilitate trustless interactions and ensure data integrity. We offer custom blockchain architecture design tailored to specific business needs, whether for finance, healthcare, or supply chain industries. Our services also include token development, asset tokenization, and NFT marketplace creation. We prioritize security in all implementations, conducting rigorous smart contract audits to prevent vulnerabilities. With expertise in permissioned and public blockchain solutions, we help enterprises adopt blockchain technology seamlessly into their operations.",
      image: s5,
      features: [
        "Smart contract development",
        "Decentralized application (DApp) solutions",
        "Secure blockchain architecture design",
      ],
    },
    {
      id: 6,
      title: "CRM / ERP Solutions",
      description:
        "Our customized CRM and ERP solutions help businesses streamline their operations, enhance productivity, and improve customer relationships.",
      long_description:
        "With a deep understanding of different industries, we tailor CRM and ERP solutions that are scalable, flexible, and integrate seamlessly with existing systems. Whether it's managing customer relationships or automating internal workflows, our solutions empower businesses to optimize every aspect of their operations, improve data-driven decision-making, and enhance productivity. Our experienced team provides end-to-end support, from initial consultation and system design to deployment and continuous maintenance.",
      image: s6,
      features: [
        "Custom ERP system development",
        "CRM integration and automation",
        "Workflow management solutions",
        "Data analytics for business intelligence",
      ],
    },
    {
      id: 7,
      title: "Application Management Services (AMS)",
      description:
        "We provide end-to-end application management, ensuring smooth performance, security, and continuous updates for business applications.",
      long_description:
        "Our AMS services focus on delivering comprehensive management for your applications. We ensure optimal performance through continuous monitoring, proactive troubleshooting, and necessary updates. Our team also handles application security, protecting your systems against potential vulnerabilities by applying the latest patches and security measures. Additionally, we assist in cloud migration, ensuring seamless transitions and ongoing management of cloud-based applications to maintain performance and scalability.",
      image: s7,
      features: [
        "Application monitoring and support",
        "Performance tuning and optimization",
        "Security patching and updates",
        "Cloud migration and management",
      ],
    },
    {
      id: 8,
      title: "Ed-Tech Product Development",
      description:
        "Empowering the education sector with innovative solutions, including Education Management Systems (EMS) and Education Resource Systems (ERS).",
      long_description:
        "We specialize in building cutting-edge Ed-Tech products that address the unique challenges of the education sector. From custom e-learning platforms and learning management systems (LMS) to student management systems (SMS) and institute ERP solutions, we create comprehensive solutions that improve the learning experience. Our tools also leverage AI-driven adaptive learning technologies to personalize content and optimize learning outcomes, ensuring that both educators and students benefit from a more engaging, efficient, and effective education environment.",
      image: s8,
      features: [
        "Our own custom e-learning platforms",
        "LMS and student management system development",
        "Institute ERP solutions",
        "Interactive content creation",
        "AI-driven adaptive learning tools",
      ],
    },
    {
      id: 9,
      title: "Repository Development & Customization",
      description:
        "We build and customize digital repositories, ensuring efficient content management and seamless integrations.",
      long_description:
        "We develop customized repository solutions to help organizations efficiently manage and organize their digital content. Our expertise in platforms like DSpace ensures that your repository is scalable, secure, and optimized for easy search and access. We work closely with your team to create tailored solutions that fit your specific needs, whether it's for academic institutions, libraries, or corporate content management systems. Our solutions also include enhanced metadata management and custom UI/UX to ensure an intuitive user experience for both content administrators and end-users.",
      image: s9,
      features: [
        "DSpace repository solutions",
        "Metadata management and search optimization",
        "Institutional repository development",
        "Custom UI/UX for repository platforms",
      ],
    },
    {
      id: 10,
      title: "Odoo Services",
      description:
        "Our comprehensive Odoo solutions cover implementation, customization, and ongoing support to optimize business workflows.",
      long_description:
        "We provide full-service Odoo solutions, from initial implementation to ongoing customization and support. Odoo's open-source ERP platform is highly adaptable, and we tailor it to suit your business processes, whether it's accounting, inventory management, or customer relationship management (CRM). Our team helps streamline workflows, improve operational efficiency, and integrate with other systems to provide a seamless experience. Additionally, we offer Odoo migration services, ensuring smooth transitions to new versions, along with cloud hosting solutions for optimal performance and scalability.",
      image: s10,
      features: [
        "Odoo module customization",
        "ERP implementation and integration",
        "E-commerce and inventory management solutions",
        "Odoo migration and cloud hosting",
      ],
    },
    {
      id: 11,
      title: "Software Training",
      description:
        "We provide professional software training programs, ensuring hands-on learning for teams and individuals in various technologies.",
      long_description:
        "Our software training programs are designed to equip your team with the necessary skills to effectively use modern enterprise applications. We offer in-depth courses in SAP, cloud computing, DevOps, AI, and data science, providing practical, hands-on learning experiences that help individuals and teams gain proficiency in essential technologies. Our training is tailored to meet the specific needs of your business, ensuring that your team stays ahead of the curve and can leverage the latest tools and technologies to drive success.",
      image: s11,
      features: [
        "Cloud computing and DevOps courses",
        "AI and data science workshops",
        "Web and mobile app development training",
      ],
    },
    {
      id:12,
      title: "SAP Training",
      image: s12,
      description:
        "Comprehensive SAP training programs tailored for enterprises and individuals.",
      long_description:
        "Our SAP training courses provide hands-on experience with real-world applications, covering core SAP modules such as SAP S/4HANA, SAP FICO, SAP MM, and SAP SD. Designed for both beginners and experienced professionals, our training helps users master SAP software, enabling them to optimize enterprise operations and gain a competitive edge in the market.",
      features: [
        "SAP and ERP training",
        "Hands-on experience with core SAP modules",
        "Customized training for businesses and individuals",
      ],
    },
  ];
  
  
  



export default  Services;
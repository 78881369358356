import classes from "./JoinAsTopperTopSection.module.css";

function JoinAsTopperTopSection() {
  return (
    <div className={classes.parent}>
      <div className={classes.imagestop}>
        <div className={classes.innerDiv}>
          <div
            style={{
              backgroundColor: "var(--chatCommentBkgColor)",
              padding: "8px",
            }}
          >
            <h1> Empowering Innovation with Technology</h1>
          </div>
          <div
            style={{
              backgroundColor: "var(--chatCommentBkgColor)",
              padding: "8px",
            }}
          >
            Building smart solutions in Web Development, Data Analytics, and AI
            to drive digital transformation
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinAsTopperTopSection;

import classes from "./Home.module.css";
import { Link } from "react-router-dom";
import {useHistory} from "react-router-dom"
import innerDiv from "../Website.module.css";
import ServicesData from "./Componets/services/servicesData";

const Services = () => {

  const history = useHistory();

  const detailsHandler = (id) => {
    history.push(`/servicesDetails/${id}`);
  };


  return (
    <div className={classes.mainWhytoppersContainer}>
      <div className={innerDiv.innerDiv}>
        <div className={classes.whyToppersTitle}>
          Our IT Services 
        </div>

        <div className={classes.whyToppersContainer}>
          {ServicesData.slice(0, 6).map((item) => (
            <div className={classes.paarent} 
            onClick={() => detailsHandler(item.id)}>
              <div className={classes.logo}>
                <img src={item.image} className={classes.imagesExam} />
              </div>
              <div className={classes.title}>{item.title}</div>
              <div className={classes.description}>{item.description}</div>
            </div>
          ))}
      
          
        </div>
      </div>
      <Link to="/it-services" className={classes.explorebutton}>
        Explore Our IT Services
      </Link>
    </div>
  );
};

export default Services;

import { useEffect, useRef, useState } from "react";
import classes from "./TeamBlock.module.css";
import TeamMemberModal from "./TeamMemberModel"; // Import the modal component

import innerDiv from "../Website.module.css";

// Import team images
import Bibhu from "./Bibhu.jpeg";
import Ipsit from "./ipsit.jpg";
import Rasmita from "./Rasmita.jpeg";
import Debansh from "./Debansh.png";
import SmrutiMadam from "./SmrutiMadam.jpeg";
import SagarPatro from "./Sagar1.jpeg";
import Ateeb from "./Ateeb.png";
import Reshwanth from "./reshwant.jpeg";
import Trushank from "./Trushank.jpeg";
import Vibhav from "./vibhav.jpeg";
import Shradha from "./Shardha.jpeg";
import Smutika from "./smutika.png";
import Soumen from "./Soumen.jpeg";
import Samarendra from "./Samarendra.jpg";
import Reeta from "./Reeta.jpeg";
import Radha from "./Radha1.jpeg";
import Kiran from "./KIran.jpg";
import Souvik from "./Souvik.png";
import Santosh from "./Santosh-Mohanty.jpg";

function OneTeamMember({ name, role, image, status, onClick }) {
  return (
    <div className={classes.teamMemberCard} onClick={onClick}>
      <img src={image} alt={name} className={classes.teamMemberImage} />
      <h3>{name}</h3>
      <p className={classes.teamRole}>{role}</p>
      {status && <p className={classes.teamStatus}>{status}</p>}
    </div>
  );
}

function TeamBlock(props) {
    const isMounted = useRef(false);
  
    useEffect(() => {
      isMounted.current = true;
      props.passMountInfo(true);
  
      return () => {
        isMounted.current = false;
        props.passMountInfo(false);
      };
    }, [props]);
  

  const [selectedMember, setSelectedMember] = useState(null);

  const leaders = [
    { 
      name: "Bibhuprasad Mahakud", 
      role: "Founder & CEO", 
      image: Bibhu, 
      education: "P.hd  in Experimental High Energy", 
      // joiningDate: "Jan 2020",
      description: "Bibhuprasad Mahakud holds a Master's degree from IIT Delhi and a Ph.D. in Physics Data Analysis from Tata Institute of Fundamental Research, Mumbai. With seven years of experience at CERN, Geneva, Switzerland, he worked extensively on proton-proton collision data produced by the Large Hadron Collider. He further pursued postdoctoral research at Purdue University and at IIT Bhubaneswar. In May 2022, he founded DiracAI Private Limited, where he currently serves as the CEO, driving innovation in the field of AI and technology." 
    }, { name: "Ipsit Panda", role: "Co-Founder", image: Ipsit, education: "MSc In Physics",
       description: "Ipsit Panda holds a Master's degree  from IIT Delhi and has extensive experience in teaching and academic leadership. He is now heading the Android Development and Algo Trading projects at DiracAI. He co-founded DiracAI Private Limited, where he serves as the Director, playing a key role in the company's vision and growth." },
    
    { 
      name: "Dr. Santosh Mohanty", 
      role: "Chief Mentor, Ex-TCS VP", 
      image: Santosh, 
      education: "Ph.D. in Data Science", 
      // joiningDate: "Feb 2019", 
      description: "Dr. Santosh Mohanty is a Trustee (https://www.npstrust.org.in/content/board-nps-trust) of the Board of NPS Trust since November, 2022 and chairs the Board’s IT Strategy Committee. He is in the Technical Advisory Committee of the PFRDA’s IT Projects. He is a Fellow at the Institute of Directors. He contributes to Vivekananda Kendra (VRMVK) activities as a Karyakarta both at state and national level. He is an advisor to Institute of Health Sciences, Bhubaneswar. He is the Chief Mentor to the startup DiracAI. Dr. Mohanty started his career as a banker for two years (1985 and 1986) and then as a university faculty both in India and in the USA for 10 years (1987 to 1997). He joined TCS in April 1997 and superannuated in March 2022. During his 25 years of professional career in TCS, he had the opportunity to lead many global responsibilities such as Head of Data Mining R&amp;D, Head of BIPM (Business Intelligence &amp; Performance Management) Practice, Head of Technology Excellence, Head of Intellectual Property Rights &amp; Product Engineering, Head of Growth &amp; Transformation Guild, and Executive sponsor for TCS’ product brand MasterCraft and Jile. Dr. Mohanty was entrusted with many leadership roles in the business ecosystem. He was the ICT (Information and Communication Technology) Industry Strategy Officer at World Economic Forum (WEF) for six years (2015 to 2020). The Open Group certified him with the title of Distinguished IT Architect (Profession Leader) in 2011. He was in the IAM (Intellectual Asset Management) Strategy 300 List for seven years (2015-16 to 2021-22) as world’s leading IP strategist. He received the LESI President’s 2018 Service Recognition Award for his stellar contribution to Licensing Executive Society and GIPC 2019 Award of Excellence in the field of innovation and IP. He was a jury member for the CII Industrial Innovation Awards 2024 and CII Industrial Intellectual Property Awards 2024."}
  ];
  

  const teamMembers = [
    { name: "Debansh Das Sharma", role: "Chief Strategic Adviser", image: Debansh, education: "MBA in Marketing", description: "Debansh Das Sharma holds a B.Sc. in Chemistry from Sambalpur University and an MBA in Marketing from Punjab Technical University. With over 10 years of experience as a Managerial Business Associate at Tata AIA, he has a strong background in business strategy and management. In 2015, he founded ClassyStreet, an online boutique, and later established Web Verbal in 2019, specializing in e-commerce strategic consulting. Currently, he is associated with DiracAI Private Limited as the Chief Strategic Adviser, contributing his expertise to drive business growth and innovation." },
    { name: "Rasmita Sahoo", role: "Advisor", image: Rasmita, education: "Ph.D. in AI", description: " Rasmita Sahoo completed her Research and Development in Science from the University of Hyderabad. She has experience as a Postdoctoral Researcher at The University of British Columbia. Currently, she is associated with DiracAI Private Limited as the Adviser, contributing her expertise in research and strategic development." },
    { name: "Smruti Dash", role: "Director", image: SmrutiMadam, education: "MBA in HR", joiningDate: "Nov 2017" },
    { name: "Sagar", role: "HR & Web-Designer", image: SagarPatro, education: "B.com", description: "Sagar Patro completed his B.Com from Vikramdev University. His strong interest in technology led him to DiracAI Private Limited, where he has been working since 2022. Currently, he serves in HR and Web Designing, contributing to both talent management and digital development within the company. His dedication and adaptability have made him an integral part of DiracAI’s growth and operations." },
    { name: "Ateeb", role: "Android Developer", image: Ateeb, education: "B.Tech in CS", description :"Ateeb Ashfaque completed his B.Tech in Computer Science from Symbiosis University of Applied Sciences. He is currently working as an Android Developer at DiracAI Private Limited, contributing to the development of innovative mobile applications." },
    { name: "Reshwanth", role: "Full Stack Developer", image: Reshwanth, education: "B.Tech in Software Engineering", description: "Reshwant completed his B.Tech in Electrical Engineering from Vignana Jyothi Institute of Engineering and Technology. He is currently working as a Full Stack Developer at DiracAI Private Limited, specializing in building and optimizing web applications."},
    { name: "Trushank", role: "Web Developer", image: Trushank, education: "B.sc in CS", description: "Trushank completed his B.Sc. in Computer Science from Model College of Science, Mumbai University. In January 2024, he joined DiracAI Private Limited as a Web Developer, contributing to the development and enhancement of web applications." },
    { name: "Vibhav", role: "Android Developer", image: Vibhav, education: "B.Tech in CS", description:"Vibhab Kumar completed his B.Tech from NIST University, Berhampur. In 2024, he joined DiracAI Private Limited as an Android Developer, focusing on mobile application development and innovation." },
    { name: "Shradha", role: "Full Stack Developer", image: Shradha, education: "B.tech in Electronic and Communication", description:"Shradha completed her graduation from IGIT Sarang and joined DiracAI Private Limited in 2024 as a Frontend Developer. She specializes in creating user-friendly and responsive web interfaces, ensuring seamless user experiences. Currently, she is transitioning into a Full Stack Developer, expanding her expertise in both frontend and backend development to contribute more comprehensively to the company's technological growth." },
    { name: "Smutikamayee", role: "Sales Development Professional", image: Smutika, education: "BBA in Sales & Marketing",description:"Smutika Mayee completed her B.Tech from Gandhi Institute of Excellence Technocrats. She began her career as a Sales Executive at Retrods and, in 2024, joined DiracAI Private Limited as a Business Development Professional, focusing on expanding the company's growth and market presence." },
    { name: "Soumen Halder", role: "Data Scientist", image: Soumen, education: "M.Sc in Data Science", description:"Soumen completed his B.Sc. from Jadavpur University and earned his Ph.D. from the Tata Institute of Fundamental Research, Mumbai. He has worked as a Visiting Researcher at KEK, Japan, and as a Researcher at the Tata Institute of Fundamental Research. In 2024, he joined DiracAI Private Limited as a Financial Data Analyst, leveraging his analytical expertise in data-driven financial insights." },
    { name: "Samarendra", role: "Data Scientist / Backend Developer", image: Samarendra, education: "M.Tech in AI", description:"Samarendra Nayak completed his M.Sc. in Physics from Delhi University and earned his Ph.D. from IIT Bhubaneswar. Since 2023, he has been working with DiracAI Private Limited, providing AI/ML project assistance, contributing his expertise in artificial intelligence and machine learning research." },
    { name: "Reeta", role: "Data Scientist", image: Reeta, education: "Ph.D. in AI & ML", joiningDate: "Aug 2023" },
    { name: "Radharani", role: "Backend Developer", image: Radha, status: "(Ex-Employee)", education: "B.Tech in CS", description: "Radha completed her B.Tech from IGIT Sarang and joined DiracAI Private Limited in 2024 as a Backend Developer, focusing on building and optimizing server-side applications." },
    { name: "Kiran", role: "Software Developer", image: Kiran, status: "(Ex-Employee)", education: "B.Tech in IT", description: "Debansh Das Sharma holds a B.Sc. in Chemistry from Sambalpur University and an MBA in Marketing from Punjab Technical University. With over 10 years of experience as a Managerial Business Associate at Tata AIA, he has a strong background in business strategy and management. In 2015, he founded ClassyStreet, an online boutique, and later established Web Verbal in 2019, specializing in e-commerce strategic consulting. Currently, he is associated with DiracAI Private Limited as the Chief Strategic Adviser, contributing his expertise to drive business growth and innovation." },
    { name: "Souvik", role: "Data Scientist", image: Souvik, status: "(Ex-Employee)", education: "MCA", joiningDate: "Oct 2021" },
];


  return (
    <div className={classes.teamBlockParent}>
      {/* About Us Section */}
    

           <div className={classes.topBlockAbout}>	 
	   <div className={innerDiv.innerDiv} style={{background: "transparent"}}>
              <div className={classes.topTitle}>
                   <h1>Our Team</h1>
	           <div style={{fontSize:"18px"}}>Meet the minds shaping the future—passionate, innovative, and unstoppable!
                   </div>
              </div>	  
           </div>
       </div>	
     

      {/* Our Team Section */}
      <div className={classes.parentOurTeam}>
      

        {/* Leadership Section (2 Cards Centered) */}
        <h3 className={classes.ourTeamSubTitle}> Our Leaders</h3>
        <div className={`${classes.teamGrid}  `}>

  {leaders.map((leader, index) => (
    <div
      key={index}
      className={`${classes.leaderCard} ${leader.name === "Dr. Santosh Mohanty" ? classes.highlightedLeader : ""} ${
        index === 1 ? classes.addSpace : ""
      }`}
    >
      <OneTeamMember {...leader} onClick={() => setSelectedMember(leader)} />
    </div>
  ))}
</div>




        {/* Team Members Section */}
        <h3 className={classes.ourTeamSubTitle}> Our Team</h3>
        <div className={classes.teamGrid}>
          {teamMembers.map((member, index) => (
            <OneTeamMember key={index} {...member} onClick={() => setSelectedMember(member)} />
          ))}
        </div>
      </div>

      {/* Render the modal when a member is selected */}
      {selectedMember && <TeamMemberModal member={selectedMember} onClose={() => setSelectedMember(null)} />}
    </div>
  );
}

export default TeamBlock;

import { useEffect, useRef, useState } from "react";
import classes from "./JoinAsTopper.module.css";

import ServicesTwo from "../Home/Componets/services/ServicesTwo";
import JoinAsTopperTopSection from "../Home/JoinAsTopperTopSection";
import Projects from "../Home/Componets/services/Projects";

const JoinAsTopper = (props) => {
  const isMounted = useRef(false);
  const [activeTab, setActiveTab] = useState("services");

  useEffect(() => {
    isMounted.current = true;
    props.passMountInfo(true);

    return () => {
      isMounted.current = false;
      props.passMountInfo(false);
    };
  }, [props]);

  return (
    <div className={classes.rulesAndRegulationsMain}>
      {/* Top Section */}
      <JoinAsTopperTopSection />

      {/* Tabs Navigation */}
      <div className={classes.tabContainer}>
        <button
          className={`${classes.tabButton} ${activeTab === "services" ? classes.activeTab : ""}`}
          onClick={() => setActiveTab("services")}
        >
          Services
        </button>
        <button
          className={`${classes.tabButton} ${activeTab === "projects" ? classes.activeTab : ""}`}
          onClick={() => setActiveTab("projects")}
        >
          Projects
        </button>
      </div>

      {/* Tab Content */}
      <div className={classes.innerDiv}>
        {activeTab === "services" ? (
          <ServicesTwo />
        ) : (
          <Projects/>
        )}
      </div>
    </div>
  );
};

export default JoinAsTopper;

import { useHistory } from "react-router-dom";
import classes from "./customServices.module.css";
import Services from "./servicesData";

const ServicesTwo = () => {
  const history = useHistory();

  const detailsHandler = (id) => {
    history.push(`/servicesDetails/${id}`);
  };

  return (
    <div className={classes.mainContainer}>
      <ul className={classes.serviceList}>
        {Services.map((service) => (
          <li
            key={service.id}
            className={classes.serviceItem}
            onClick={() => detailsHandler(service.id)}
          >
            <img
              src={service.image}
              alt={service.title}
              className={classes.serviceImage}
            />
            <div className={classes.serviceContent}>
              <h3 className={classes.serviceTitle}>{service.title}</h3>
              <p className={classes.serviceDescription}>
                {service.description}
              </p>
              {service.features && (
                <ul className={classes.featureList}>
                  {service.features.map((feature, index) => (
                    <li key={index} className={classes.featureItem}>
                      {" "}
                      {feature}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ServicesTwo;

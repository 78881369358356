
import ServicesDetails from "./ServicesDetails";
import { useParams } from "react-router-dom";


// Dynamic ServiceDetails Component
const ServiceDetailsWrapper = ({ passMountInfo }) => {
  const { id } = useParams();


  return (
    <ServicesDetails
      id={id}
      passMountInfo={passMountInfo}
    />
  );
};


export default ServiceDetailsWrapper; 

